<template>
  <div>
    <v-img
      :src="bannerCapa"
      max-height="400px"
    >
      <div class="d-flex align-center capa-container">
        <v-row>
          <v-col cols="12" md="3" offset-md="6" class="text-center text-sm-left">
            <div class="display-1 font-weight-bold">
              <slot name="title">
              </slot>
            </div>
            <div class="mt-3">
              <slot name="text">
              </slot>
            </div>
            <div class="mt-3" v-if="showActionBtn">
              <slot name="action">
                <v-btn large
                       :x-large="xLarge"
                       :target="target"
                       :link="hasLocalLink"
                       :href="externalLink"
                       :to="link"
                       @click="onClickBtn"
                       color="secondary"
                       class="normal-btn-text elevation-0 rounded-tag-small">
                  {{ btnText }}
                </v-btn>
              </slot>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-img>
  </div>
</template>

<script>
import banner from '../../assets/banner-area-comprador-institucional.png';

export default {
  props: {
    bannerCapa: {
      type: [String, Object],
      default: banner,
    },
    showActionBtn: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: 'Quero cadastrar minha empresa',
    },
    btnLink: {
      type: String,
      default: '/criar-conta',
    },
    externalLink: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      if (this.externalLink || this.disableLink) {
        return null;
      }
      return this.btnLink;
    },
    hasLocalLink() {
      return !this.externalLink && !this.disableLink;
    },
  },
  methods: {
    onClickBtn() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.capa-container {
  height: 400px;
}
</style>
