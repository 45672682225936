<template>
  <div class="d-flex">
    <div class="d-flex">
      <v-img
        width="120px"
        class="d-inline-block ml-3"
        :src="img"
      />
    </div>
    <div class="d-flex flex-column justify-center ml-1">
      <div class="body-2 font-weight-bold">
        {{ title }}
      </div>
      <div class="body-2">
        <slot name="text">
        </slot>
      </div>
    </div>
    <div class="d-flex align-center arrow-container">
      <v-img
        v-if="showArrow"
        width="25px"
        class="ml-3"
        src="../../assets/como-funciona/seta.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    img: {
      type: String,
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.arrow-container {
  min-width: 55px;
}
</style>
