<template>
  <div>
    <v-carousel
      cycle
      class="rounded-card"
      height="378"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(item,i) in banners"
        :key="i"
        :src="item.foto"
        link
        :to="item.link"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: {
    banners: Array,
  },
};
</script>
